import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiPage } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import UltimateGuideOnlineMarketplace from '~/views/EbookLandings/UltimateGuideOnlineMarketplace'

const UltimateGuideOnlineMarketplacePage = () => (
  <UltimateGuideOnlineMarketplace />
)

export default UltimateGuideOnlineMarketplacePage

export const query = graphql`
  query {
    strapiPage(
      page_name: {
        eq: "Ultimate guide on how to kickstart online marketplace business"
      }
    ) {
      ...MetaTagInformationPages
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiPage>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/ultimate-guide-on-how-to-kickstart-online-marketplace-business/"
    />
  )
}
