import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useUltimateGuideOnlineMarketplaceStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      imageEbook: file(
        relativePath: { eq: "ebook/ultimate-guide-online-marketplace.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useUltimateGuideOnlineMarketplaceStaticQuery
