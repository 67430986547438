import { getImage } from 'gatsby-plugin-image'

import EbookForm from '~/components/forms/EbookForm'
import MainLayout from '~/components/layouts/MainLayout'
import LazyHydrate from '~/components/shared/LazyHydrate'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { fileToImageLikeData } from '~/utils'
import InfoSectionLeadMagnets from '~/views/EbookLandings/components/InfoSectionLeadMagnets'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'
import useUltimateGuideOnlineMarketplaceStaticQuery from '~/views/EbookLandings/UltimateGuideOnlineMarketplace/useUltimateGuideOnlineMarketplaceStaticQuery'

const dataList = [
  'Choosing the niche and the platform type',
  'How you can make money with your marketplace',
  'How to attract buyers and sellers to your website',
  'What functionality you should include in your marketplace',
  'How much a standard marketplace website costs (and how long it takes).',
]

const UltimateGuideOnlineMarketplace = () => {
  const query = useUltimateGuideOnlineMarketplaceStaticQuery()
  const imageEbook = getImage(fileToImageLikeData(query.imageEbook))

  return (
    <MainLayout isTransparent>
      <PrimeSectionLeadMagnets
        title="Ultimate Guide to Kickstart Online Marketplace Business [Free E-book]"
        description="Are you inspired by the success of Airbnb, Amazon and Fiverr? Do you want to build your own custom marketplace, but don’t know where to start?"
        maxWidthTitle="900px"
        maxWidthDescription="800px"
      />

      <InfoSectionLeadMagnets
        imageEbook={imageEbook}
        imageClass="ultimateGuideOnlineMarketplace"
        imageMeta="Ultimate Guide on How to Kickstart Online Marketplace Business | Codica"
        description="Our expert team has prepared a detailed guide on how to start building your platform. This in-depth e-book covers all you need to know about creating a marketplace website, including:"
        dataList={dataList}
      />

      <LazyHydrate whenVisible>
        <ReCaptchaProvider>
          <EbookForm
            dataId="downloadEbookButton"
            formTitle="Download our free guide and know it all!"
            fileName="Ultimate Guide on How to Kickstart Online Marketplace Business"
            urlPath="thank-you-custom-marketplace-development-guide"
          />
        </ReCaptchaProvider>
      </LazyHydrate>
    </MainLayout>
  )
}

export default UltimateGuideOnlineMarketplace
